import { defineStore } from 'pinia';
import { useAuthStore } from './authentication';
import { getPhotos, getMedias } from '@/api/performer';
import { mediaPaidInfo } from '@/api/videos';

export interface MediaState {
    settings: any;
    photoType: any;
    photoGroup: any;
    videoType: any;
    videoGroup: any;
}

const photoFilterDefault: any = { limit: 200, offset: 0, paid: 0 };
const videoFilterDefault: any = { limit: 20, offset: 0, paid: 0 };

export const useMediaStore = defineStore('media', {
    state: (): Partial<MediaState> => ({
        settings: {
            free: {add: false, errNo: undefined}, 
            paid: {add: false, errNo: undefined}, 
            balance: {},
            levels: {}, 
            price: {
                photo: {},
                video: {}
            }
        },
        photoType: 0,
        photoGroup: {
            pending: {
                photos: [],
                total: 0
            },
            approved: {
                photos: [],
                total: 0
            },
            rejected: {
                photos: [],
                total: 0
            },
        },
        videoType: 0,
        videoGroup: {
            new: {
                medias: [],
                total: 0
            },
            pending: {
                medias: [],
                total: 0
            },
            approved: {
                medias: [],
                total: 0
            },
            rejected: {
                medias: [],
                total: 0
            },
        }
    }),
    actions: {
        async initialize() {
            this.getPaidInfo();

            this.getPhotos(0);
            this.getVideos(0);
        },
        async getPaidInfo() {
            const { result, error } = await mediaPaidInfo();

            if(error && error!.code === 204){
                return;
            }

            this.settings.free.add = result.free.add;
            this.settings.free.errNo = result.free.errNo;
            this.settings.paid.add = result.paid.add;
            this.settings.paid.errNo = result.paid.errNo;
            this.settings.balance = result.paid.balance;
            this.settings.levels = result.levels;
            this.settings.price = result.price;
        },
        async getPhotos(paid?: number){
            const user = useAuthStore();

            let filter = photoFilterDefault;
            this.photoType = paid != undefined ? paid : this.photoType;
            filter.paid = this.photoType;

            const { error, result } = await getPhotos(user.account.id, filter);
            if(error){
                return;
            }

            this.photoGroup.approved = result.approved;
            this.photoGroup.pending = result.pending;
            this.photoGroup.rejected = result.rejected;
        },
        async getVideos(paid?: number){
            const user = useAuthStore();

            let filter = videoFilterDefault;
            this.videoType = paid != undefined ? paid : this.videoType;
            filter.paid = this.videoType;

            const { error, result } = await getMedias(user.account.id, filter);

            if(error){
                return;
            }

            this.videoGroup.new = result.new;
            this.videoGroup.approved = result.approved;
            this.videoGroup.pending = result.pending;
            this.videoGroup.rejected = result.rejected;
        },
        canAddMedia(paid: boolean){
            const type = paid ? 'paid' : 'free';
            return this.settings[type].add;
        },
        getPrices(type: string){
            return this.settings.price[type];
        },
    },
    getters: {
        isPaidPhotos(state: any) {
            return state.photoType === 1;
        },
        isPaidVideos(state: any) {
            return state.videoType === 1;
        },
        getBalance(state: any){
            return state.settings.balance;
        },
        getLevels(state: any){
            return state.settings.levels;
        }
    }
});
