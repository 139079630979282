import { get, delEte, put } from '../fetch-extension';

export async function delVideo(performerId: number, media: any){
    const { error, result } = await delEte<any>(`performer/performer_accounts/${performerId}/medias/${media.id}`, {body: media});

    if (error) {
        return { error };
    }

    return { result: result };
}

export async function mediaPaidInfo(): Promise<any> {
    const response = await get<any>(`performer/medias/info_paid`);

    return response;
}

export async function swapVideo(videoOne: number, videoTwo: number): Promise<any> {
    const response = await get<any>(`performer/medias/swap/${videoOne}/${videoTwo}`);

    return response;
}

export async function selectVideo(videoId: number, type: string): Promise<any> {
    const { error, result } = await put<any>(`performer/medias/${videoId}/${type}`);

    if (error) {
        return { error };
    }

    return { result: result };
}

export async function editVideo(videoId: number, body: any): Promise<any> {
    const { error, result } = await put<any>(`performer/medias/${videoId}`, { body });

    if (error) {
        return { error };
    }

    return { result: result };
}

export async function avatarVideo(performerId: number, videoId: number) {
    const body = { avatarMedia: { id: videoId }, id: performerId }
    const { error, result } = await put<any>(`performer/performer_accounts/${performerId}`, {body});

    if (error) {
        return { error };
    }

    return { result: result };
}